import React, { useContext } from "react";
import Modal from "../Modals/Modal";
import {
  color_page_wrapper,
  color_page_main,
  second_section_color_page,
  second_section_color_page_main,
  section_color_page_white,
} from "../../styles/modules/color_page.module.css";
import IntroColor from "../../../coloreshtml/template/components/introColor.js";
import { ContextLocale } from "../../context/ContextLocale";
import MonocromathicSection from "../../../coloreshtml/template/components/monocromathicSection";
import ColorCompositionSection from "../../../coloreshtml/template/components/colorCompositionSection";
import SectionColorSchemesAndShades from "../../../coloreshtml/template/components/sectionColorSchemesAndShades";
import ColorContrastCheckerInPost from "../../../coloreshtml/template/components/contrastChecker/ContrastCheckerInPost";
import CodeSnippetColor from "../../../coloreshtml/template/components/codeSnippetColor";

export const ColorPageModal = ({ color }) => {
  const { lang } = useContext(ContextLocale);
  const colorHex = color;
  const colorName = color;

  return (
    <Modal color={color}>
      <section
        className={color_page_wrapper}
        style={{
          backgroundColor: colorHex,
        }}
      >
        <main className={color_page_main}>
          <IntroColor
            colorName={colorName}
            colorHex={colorHex}
            withImage={false}
            locale={lang}
          />
          <MonocromathicSection
            locale={lang}
            colorName={colorName}
            colorHex={colorHex}
          />
          <ColorCompositionSection
            locale={lang}
            colorName={colorName}
            colorHex={colorHex}
          />
        </main>
      </section>

      <section
        className={second_section_color_page}
        style={{
          backgroundColor: colorHex,
        }}
      >
        <div className={second_section_color_page_main}>
          <SectionColorSchemesAndShades
            colorName={colorName}
            colorHex={colorHex}
            locale={lang}
          />
        </div>
      </section>
      <section
        className={second_section_color_page}
        style={{
          backgroundColor: colorHex,
        }}
      >
        <div className={section_color_page_white}>
          <ColorContrastCheckerInPost
            colorName={colorName}
            colorHex={colorHex}
            locale={lang}
          />
        </div>
      </section>
      <section
        className={second_section_color_page}
        style={{
          backgroundColor: colorHex,
        }}
      >
        <div className={section_color_page_white}>
          <CodeSnippetColor
            locale={lang}
            colorName={colorName}
            colorHex={colorHex}
          />
        </div>
      </section>
      <section
        className={second_section_color_page}
        style={{
          backgroundColor: colorHex,
        }}
      ></section>
    </Modal>
  );
};
